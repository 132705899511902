<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header a-tourists-dialog-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>
									<span class='a-card-details-header-title-arrow-back' @click.stop='closeDialog'>
										<v-icon icon='arrow-prev' size='14' />
									</span>
									{{cardPassport.id ? $t("account.tourists.edit_document") : $t("account.tourists.add_document")}}
								</p>
							</div>
						</div>
					</div>
					<div class='a-card-details-content a-add-passport-content'>
						<div class='a-add-passport-type'>
							<div class='a-add-passport-type-item' :class='{"active": form.type === "passport"}' @click='form.type = "passport"'>
								<span>{{$t("account.tourists.passport")}}</span>
							</div>
							<div class='a-add-passport-type-item' :class='{"active": form.type === "ID"}' @click='form.type = "ID"'>
								<span>{{$t("account.tourists.id_card")}}</span>
							</div>
						</div>

						<div class='a-add-passport-form'>
							<div class="row">
								<div class="col-6">
									<v-text-field
										:label='form.type === "ID" ? $t("account.form.label.number_card") : $t("account.form.label.series_and_number")'
										name='number'
										v-model='form.number'
										isRegex
										:regex='/^[A-Za-z0-9]+$/'
										isUpperCase
									/>
								</div>
								<div class="col-6">
									<div class='a-add-passport-form-select'>
										<span class="app-label">
											{{ form.type === "ID" ? $t("account.form.label.country_of_issue_id") : $t("account.form.label.country_of_passport_issuance") }}:
										</span>
										<a-autocomplete
											:placeholder='form.type === "ID" ? $t("account.form.label.country_of_issue_id") : $t("account.form.label.country_of_passport_issuance")'
											v-model='form.country_residence_code'
											:requestName='getAutocompleteNationality'
											item-value='code'
											actionInitial
											small
											append-icon='arrow-down'
											:initialLoading='initialLoading'
										/>
									</div>
								</div>
								<div class="col-6">
									<p class='app-label'>{{$t("account.form.label.expiration_date")}}:</p>
									<a-dropdown-date-picker
										v-model='form.date_expiration'
										@update-date='form.date_expiration = $event'
										hideLabels
										isFuture
										class='a-add-passport-form-date'
									/>
								</div>
								<div class="col-6">
									<p class='app-label'>{{$t("account.form.label.date_of_issue")}}:</p>
									<a-dropdown-date-picker
										v-model='form.date_issue'
										@update-date='form.date_issue = $event'
										hideLabels
										class='a-add-passport-form-date'
										:errorMessage='form.date_issue > $moment().format("YYYY-MM-DD") ? $t("account.form.hint.cannot_be_issued_later_than_today") : null'
									/>
								</div>
							</div>
							<div class="a-add-passport-divider"></div>

							<div class='a-add-passport-group-radio'>
								<div>
									<v-radio
										id="man"
										name='gender'
										:label='$t("account.form.label.man")'
										value='man'
										v-model='form.gender'
									/>
								</div>
								<div>
									<v-radio
										id="woman"
										name='gender'
										:label='$t("account.form.label.woman")'
										value='woman'
										v-model='form.gender'
									/>
								</div>
							</div>
							<div class="a-add-passport-divider"></div>

							<div class="row">
								<div class="col-6">
									<v-text-field
										:label='$t("account.form.label.first_name")'
										name='name'
										v-model='form.name'
										:hint='$t("account.form.hint.as_indicated_in_the_document")'
										isRegex
										:regex='/^[A-Za-z]+$/'
										isUpperCase
									/>
								</div>
								<div class="col-6">
									<v-text-field
										:label='$t("account.form.label.last_name")'
										name='surname'
										v-model='form.surname'
										:hint='$t("account.form.hint.as_indicated_in_the_document")'
										isRegex
										:regex='/^[A-Za-z]+$/'
										isUpperCase
									/>
								</div>
							</div>
							<div class="a-add-passport-divider"></div>

							<div class="row">
								<div class="col-6">
									<p class='app-label'>{{$t("account.form.label.date_of_birth")}}:</p>
									<a-dropdown-date-picker
										v-model='form.birthday'
										@update-date='form.birthday = $event'
										class='a-add-passport-form-date'
										hideLabels
									/>
								</div>
							</div>
							<div class="a-add-passport-divider"></div>

							<div class="row">
								<div class="col-6">
									<div class='a-add-passport-form-select'>
										<span class="app-label">{{$t("account.form.placeholder.nationality")}}:</span>
										<a-autocomplete
											:placeholder='$t("account.form.placeholder.nationality")'
											v-model='form.nationality_code'
											:requestName='getAutocompleteNationality'
											item-value='code'
											actionInitial
											small
											append-icon='arrow-down'
											:initialLoading='initialLoading'
										/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<div class='a-add-passport-form-select'>
										<span class="app-label">{{$t("account.form.placeholder.country_of_residence")}}:</span>
										<a-autocomplete
											:placeholder='$t("account.form.placeholder.country_of_residence")'
											v-model='form.country_code'
											:requestName='getAutocompleteNationality'
											item-value='code'
											actionInitial
											small
											append-icon='arrow-down'
											:initialLoading='initialLoading'
										/>
									</div>
								</div>
							</div>
							<div class="a-add-passport-divider"></div>

							<v-checkbox
								name='confirm_info'
								:label='$t("account.tourists.i_confirm_that")'
								v-model='form.confirm_info'
							/>
						</div>
					</div>

					<div class='a-card-details-footer a-add-passport-footer'>
						<div class='a-card-details-footer__divider'>
							<div class='divider' />
						</div>

						<div class="row align-items-center a-add-passport-footer-row">
							<div class="col">
								<p class='a-text-with-icon' style='color: rgba(0,0,0,.3); margin: 20px 0'>
									<v-icon icon='lock' size='13' />
									<span>{{$t("account.tourists.your_data_will_be_securely_encrypted")}}</span>
								</p>
							</div>
							<div class="col-auto">
								<div class='a-form-tourists-group-buttons'>
									<v-button xxsmall outline color='green' @click.stop='closeDialog'>{{$t("account.buttons.cancel")}}</v-button>
									<v-button xxsmall color='green white--text' class='a-form-tourists-button-add'
										@click='cardPassport.id ? updatePassport() : addPassport()'
										:loading='loading_btn'
										:disabled='!(form.confirm_info && form.date_issue <= $moment().format("YYYY-MM-DD"))'
									>{{$t("account.buttons.save")}}</v-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import ADropdownDatePicker from '@/components/account/ADropdownDatePicker';
	import { addPassport, updatePassport, getAutocompleteNationality } from '@/services/request';

	export default {
		data: () => ({
			form: {
				type: 'passport',
				number: '',
				country_residence_code: null,
				date_issue: null,
				date_expiration: null,
				gender: null,
				name: '',
				surname: '',
				birthday: null,
				nationality_code: null,
				country_code: null,
				confirm_info: false,
				errors: {}
			},
			loading_btn: false,
			initialLoading: false
		}),
		props: ['value', 'card', 'cardPassport'],
		components: {
			ADropdownDatePicker
		},
		methods: {
			getAutocompleteNationality,
			addPassport() {
				this.request(addPassport, null, this.$t('account.messages.success.passport_successfully_added'))
			},
			updatePassport() {
				this.request(updatePassport, {passportId: this.cardPassport.id}, this.$t('account.messages.success.passport_successfully_update'))
			},
			request(request, passportId, titleSuccess) {
				this.loading_btn = true;

				request({
					touristId: this.card.id,
					data: this.form,
					...passportId
				}).then(() => {
					this.$store.commit('notification/successMessage', {title: titleSuccess});
					this.$emit("update-item");
					this.closeDialog();
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading_btn = false)
			},
			closeDialog() {
				this.$emit('input', false);
				this.$emit('clear-card-edit');
			}
		},
		watch: {
			value() {
				if(this.value) {
					if(this.cardPassport.id) {
						this.form = Object.assign(this.form, this.cardPassport, {confirm_info: true});
						this.initialLoading = true;
					}
				} else {
					this.form = {
						type: 'passport',
						number: '',
						country_residence_code: null,
						date_issue: null,
						date_expiration: null,
						gender: null,
						name: '',
						surname: '',
						birthday: null,
						nationality_code: null,
						country_code: null,
						confirm_info: false
					}

					this.loading_btn = false;
					this.initialLoading = false;
				}
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-add-passport {
		&-content {
			@include minw( $grid-breakpoints-md ) {
				padding: 50px * .9 55px * .9;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 50px 55px;
			}
			@include maxw($grid-breakpoints-xs - 1) {
				height: calc(100% - 76px - 115px);
			}
		}
		&-type {
			display: flex;
			align-items: center;
			border: 1px solid rgba($black, .1);
			border-radius: 5px;
			overflow: hidden;
			margin-bottom: 30px;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 50px;
			}
			&-item {
				font-size: 13px;
				font-weight: 400;
				flex: 0 0 calc(100% / 2);
				max-width: calc(100% / 2);
				text-align: center;
				padding: 12px;
				cursor: pointer;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 16px;
				}
				&.active:before {
					background-color: $green-persian;
				}
				&:before {
					content: '';
					position: absolute;
					right: 0;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 2px;
				}
			}
		}
		&-form {
			.row {
				margin: 0 -10px;
			}
			.col-6 {
				padding-right: 10px;
				padding-left: 10px;
				@include minw($grid-breakpoints-xs) {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
			}
			&-select {
				margin-bottom: 25px;
			}
			.app-input,
			.app-radio,
			.a-add-passport-form-date {
				@include maxw($grid-breakpoints-xs - 1) {
					margin-bottom: 20px;
				}
			}
		}
		&-divider {
			@include minw($grid-breakpoints-xs) {
				margin-top: 35px;
				margin-bottom: 25px;
				border-bottom: 1px solid rgba($black, .1);
			}
		}
		&-group-radio {
			display: flex;
			flex-direction: row;
			& > div {
				&:first-child {
					margin-right: 40px;
					min-width: 95px;
				}
			}
		}
		&-footer {
			@include maxw($grid-breakpoints-xs - 1) {
				height: 115px;
			}
			&-row {
				@include maxw($grid-breakpoints-xs - 1) {
					flex-direction: column;
					.col {
						flex-grow: 0;
					}
				}
			}
		}
	}
</style>
