<template>
	<div class='a-data-table-simple'>
		<table class='a-data-table-simple-table mobile-hide'>
			<tbody>
				<tr class='a-data-table-simple-row'
					v-for='(item, i) in items'
					:key='i'
					@click='$emit("action-row", item)'
				>
					<td class='a-data-table-simple-cell'>
						<a-user :user='item' />
					</td>
					<td class='a-data-table-simple-cell'>
						<p class='a-data-table-simple-text'>{{$t("account.tourists.add")}} {{$moment(item.created_at).format("DD/MM/YYYY")}}</p>
					</td>
					<td class='a-data-table-simple-cell'>
						<p class='a-data-table-simple-text'>{{item.email}}</p>
					</td>
					<td class='a-data-table-simple-cell' width='1'>
						<p class='a-data-table-simple-text'>{{item.phone | VMask('+### (##) ### ## ##') }}</p>
					</td>
				</tr>
			</tbody>
		</table>
		<div class='a-data-table-simple-table mobile-show'>
			<div class='a-data-table-simple-row'
				v-for='(item, i) in items'
				:key='i'
				@click='$emit("action-row", item)'
			>
				<div class='a-data-table-simple-cell name'>
					<a-user :user='item' />
				</div>
				<div class='a-data-table-simple-cell date'>
					<p class='a-data-table-simple-text'>{{$t("account.tourists.add")}} {{item.created_at}}</p>
				</div>
				<div class='a-data-table-simple-cell mail'>
					<p class='a-data-table-simple-text'>{{item.email}}</p>
				</div>
				<div class='a-data-table-simple-cell phone'>
					<p class='a-data-table-simple-text'>{{item.phone | VMask('+### (##) ### ## ##') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import masker from 'vue-the-mask/src/masker';
	import tokens from 'vue-the-mask/src/tokens';

	export default {
		filters: {
			VMask: (value, mask) => masker(value, mask, true, tokens)
		},
		props: ['items']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-data-table-simple {
		@include minw( $grid-breakpoints-xs ) {
			overflow-y: hidden;
			overflow-x: auto;
			margin: -20px;
			padding: 20px;
		}
		&-table {
			width: 100%;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				display: grid;
			}
		}
		&-row {
			border-bottom: 1px solid rgba($black, .05);
			transition: box-shadow $transition;
			@include minw( $grid-breakpoints-xs ) {
				&:hover {
					cursor: pointer;
					box-shadow: 0px 0px 30px rgba(86, 86, 86, 0.2);
				}
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				padding: 20px 0;
				flex-wrap: wrap;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		&-cell {
			padding-bottom: 5px;
			vertical-align: middle;
			@include minw( $grid-breakpoints-xs ) {
				padding: 15px * .6 25px * .6;
				white-space: nowrap;
			}
			@include minw( $grid-breakpoints-sm ) {
				padding: 15px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 15px 25px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				&.name,
				&.date {
					flex: 1 1 100%;
				}
				&.date,
				&.mail,
				&.phone {
					padding-left: 50px;
				}
				&.phone {
					padding-right: 25px;
				}
			}
		}
		&-text {
			font-size: 13px;
			font-weight: 400;
			color: rgba($black, .6);
		}
	}
</style>
