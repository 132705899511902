<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details a-loyalty-program' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header a-tourists-dialog-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>
									<span class='a-card-details-header-title-arrow-back' @click.stop='closeDialog'>
										<v-icon icon='arrow-prev' size='14' />
									</span>
									{{cardLoyaltyProgram.id ? $t("account.tourists.edit_loyalty_program") : $t("account.tourists.add_loyalty_program")}}
								</p>
							</div>
						</div>
					</div>
					<ValidationObserver ref="form" v-slot="{ invalid }">
						<div class='a-card-details-content'>
							<div class="row">
								<div class="col-6">
									<ValidationProvider rules="required">
										<a-select
											:label='$t("account.form.label.name_program")'
											:placeholder='`${$t("account.form.placeholder.choose")}...`'
											:options='itemsLoyaltyPrograms'
											v-model='form.program_id'
											small
											class='a-loyalty-program-select'
										/>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<ValidationProvider :name='$t("account.form.placeholder.number_program")' rules="required|numeric" v-slot='{errors}'>
										<v-text-field
											:label='$t("account.form.placeholder.number_program")'
											placeholder="01010101"
											name='number'
											v-model='form.number'
											:errorMessage='errors[0]'
										/>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<div class='a-card-details-footer'>
							<div class='a-card-details-footer__divider'>
								<div class='divider' />
							</div>

							<div class="row align-items-center">
								<div class="col-auto ml-auto">
									<div class='a-form-tourists-group-buttons'>
										<v-button xxsmall outline color='green' @click.stop='closeDialog'>{{$t("account.buttons.cancel")}}</v-button>
										<v-button xxsmall color='green white--text' class='a-form-tourists-button-add' @click='cardLoyaltyProgram.id ? updateLoyaltyProgram() : addLoyaltyProgram()' :loading='loading_btn' :disabled='invalid'>{{$t("account.buttons.save")}}</v-button>
									</div>
								</div>
							</div>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import { addLoyaltyProgram, updateLoyaltyProgram, getAutocompleteLoyaltyPrograms } from '@/services/request';

	export default {
		data: () => ({
			form: {
				program_id: null,
				number: ''
			},
			itemsLoyaltyPrograms: [],
			loading_btn: false,
			errors: {}
		}),
		props: ['value', 'card', 'cardLoyaltyProgram'],
		methods: {
			addLoyaltyProgram() {
				this.request(addLoyaltyProgram, null, this.$t('account.messages.success.loyalty_program_successfully_added'))
			},
			updateLoyaltyProgram() {
				this.request(updateLoyaltyProgram, {programId: this.cardLoyaltyProgram.id}, this.$t('account.messages.success.loyalty_program_successfully_update'))
			},
			request(request, programId, titleSuccess) {
				this.loading_btn = true;

				request({
					touristId: this.card.id,
					data: this.form,
					...programId
				}).then(() => {
					this.$store.commit('notification/successMessage', {title: titleSuccess});
					this.$emit("update-item");
					this.closeDialog();
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading_btn = false)
			},
			closeDialog() {
				this.$emit('input', false);
				this.$emit('clear-card-edit');
			}
		},
		watch: {
			value() {
				if(this.value) {
					getAutocompleteLoyaltyPrograms().then((res) => {
						this.itemsLoyaltyPrograms = res.data

						if(this.cardLoyaltyProgram.id) {
							this.form.number = this.cardLoyaltyProgram.number;
							this.form.program_id = this.cardLoyaltyProgram.programId;
						}
					});
				} else {
					this.form = {
						program_id: null,
						number: ''
					},
					this.itemsLoyaltyPrograms = [],
					this.loading_btn = false
				}
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-loyalty-program {
		.col-6 {
			@include minw($grid-breakpoints-xs) {
				flex: 0 0 calc(100% / 2);
				max-width: calc(100% / 2);
			}
		}
		&-select {
			margin-bottom: 25px;
		}
	}
</style>
