<template>
	<div>
		<a-profile :value='value'
                   :user='user'
                   :showId="true"
                   :loading='loading'
                   @input='$emit("input"), user = {}'
                   :double='editTourist || addPassport || dialogDeletePassport || addLoyaltyProgram || dialogDeleteLoyaltyProgram'>
			<template v-slot:action>
				<span class='app-link green--text' @click='editTourist = true' v-if='!loading'>{{$t("account.tourists.edit_data")}}</span>
			</template>
			<template v-slot:content>
				<div class='a-profile-content' v-if='!loading'>
					<div class='a-profile-content-block'>
						<div class="row align-items-center a-profile-content-row">
							<div class="col">
								<p class='a-sub-heading'>{{$t("account.tourists.document_data")}}</p>
							</div>
							<div class="col-auto">
								<span class='app-link green--text' @click='addPassport = true'>
									{{$t('account.buttons.add')}} <span class='mobile-hide'> {{$t("account.tourists.document")}}</span>
								</span>
							</div>
						</div>

						<div class='a-tourists-group-row' v-if='user.passports && user.passports.length'>
							<div class="a-tourists-group-col" v-for='(item, i) in user.passports' :key='i'>
								<a-profile-card
									:item='item'
									:title='`${item.type === "ID" ? $t("account.tourists.id_card") : $t("account.tourists.passport")} ${item.country_residence_name ? `(${item.country_residence_name})` : ""}`'
									:tourist='item.name ? item.name : "" + " " + item.surname ? item.surname : ""'
									:passport='item.number'
									:date='item.date_expiration ? `${$t("account.tourists.ends")} ${$moment(item.date_expiration).format("MMM DD, YYYY")}` : ""'
									:isMain='item.is_main'
									:items_menu='items_passports'
								/>
							</div>
						</div>

						<p class='a-profile-content-descr' v-else>{{$t("account.tourists.no_documents_attached")}}</p>
					</div>

					<div class='a-profile-content-block'>
						<div class="row align-items-center a-profile-content-row">
							<div class="col">
								<p class='a-sub-heading'>{{$t("account.tourists.loyalty_program_data")}}</p>
							</div>
							<div class="col-auto">
								<span class='app-link green--text' @click='addLoyaltyProgram = true'>
									{{$t('account.buttons.add')}} <span class='mobile-hide'> {{$t("account.tourists.loyalty_program")}}</span>
								</span>
							</div>
						</div>

						<div class='a-tourists-group-row' v-if='user.programs && user.programs.length'>
							<div class="a-tourists-group-col" v-for='(item, i) in user.programs' :key='i'>
								<a-profile-card
									:item='item'
									:title='item.name'
									:passport='item.number'
									:items_menu='items_loyalty'
								/>
							</div>
						</div>

						<p class='a-profile-content-descr' v-else>{{$t("account.tourists.no_loyalty_program_attached")}}</p>
					</div>
                    <div class='a-profile-content-block'>
                        <v-button xxsmall color='red white--text' @click='dialogDeleteTourist = true'>{{$t("account.tourists.delete")}}</v-button>
                    </div>
				</div>
			</template>
		</a-profile>

		<form-tourist
			:value='addTourist'
			@input='$emit("close-form-add-tourist")'
			@update-items='$emit("update-items")'
			@update-item='getTourist'
		/>

		<form-tourist
			v-model='editTourist'
			:edit='true'
			:card='user'
			@update-items='$emit("update-items")'
			@update-item='getTourist'
		/>

		<add-passport
			v-model='addPassport'
			:card='card'
			:cardPassport='cardPassport'
			@clear-card-edit='cardPassport = {}'
			@update-item='getTourist'
		/>
		<a-dialog-delete
			v-model='dialogDeletePassport'
			:title='$t("account.tourists.are_you_sure_you_want_to_delete_this_document")'
			@close-dialog="dialogDeletePassport = false, cardPassport = {}"
			@action-dialog='deletePassport()'
			:loading='loading_btn'
		/>

        <a-dialog-delete
            v-model='dialogDeleteTourist'
            :title='$t("account.tourists.are_you_sure_you_want_to_delete_tourist")'
            @close-dialog="dialogDeleteTourist = false"
            @action-dialog='deleteTourist()'
            :loading='loading_btn'
        />

		<add-loyalty-program
			v-model='addLoyaltyProgram'
			:card='card'
			:cardLoyaltyProgram='cardLoyaltyProgram'
			@clear-card-edit='cardLoyaltyProgram = {}'
			@update-item='getTourist'
		/>
		<a-dialog-delete
			v-model='dialogDeleteLoyaltyProgram'
			:title='$t("account.tourists.are_you_sure_you_want_to_delete_this_loyalty_program")'
			@close-dialog="dialogDeleteLoyaltyProgram = false, cardLoyaltyProgram = {}"
			@action-dialog='deleteLoyaltyProgram()'
			:loading='loading_btn'
		/>
	</div>
</template>

<script>
	import AProfile from '@/components/account/AProfile';
	import AProfileCard from '@/views/account/tourists/AProfileCard';
	import FormTourist from '@/views/account/tourists/FormTourist';
	import AddPassport from '@/views/account/tourists/AddPassport';
	import AddLoyaltyProgram from '@/views/account/tourists/AddLoyaltyProgram';
	import ADialogDelete from '@/components/account/dialogs/delete';
	import { getTourist, deleteTourist, passportSetMain, deletePassport, deleteLoyaltyProgram } from '@/services/request';
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			user: {},
			cardPassport: {},
			cardLoyaltyProgram: {},
			loading: false,
			editTourist: false,
			addPassport: false,
			dialogDeletePassport: false,
            dialogDeleteTourist: false,
			addLoyaltyProgram: false,
			dialogDeleteLoyaltyProgram: false,
			loading_btn: false
		}),
		props: ['value', 'card', 'addTourist'],
		computed: {
			items_passports() {
				return [
					{name: i18n.t("account.buttons.make_it_basic"), callback: ($event) => this.passportSetMain($event)},
					{name: i18n.t("account.buttons.edit"), callback: ($event) => this.editPassport($event)},
					{name: i18n.t('account.buttons.remove'), color: 'error--text', callback: ($event) => this.openDeletePassport($event)}
				]
			},
			items_loyalty() {
				return [
					{name: i18n.t("account.buttons.edit"), callback: ($event) => this.editLoyaltyProgram($event) },
					{name: i18n.t('account.buttons.remove'), color: 'error--text', callback: ($event) => this.openDeleteLoyaltyProgram($event)}
				]
			}
		},
		components: {
			AProfile,
			AProfileCard,
			FormTourist,
			ADialogDelete,
			AddPassport,
			AddLoyaltyProgram
		},
		created() {
			if(this.card.id) this.getTourist();
		},
		methods: {
			editPassport(item) {
				this.cardPassport = item;
				this.addPassport = true;
			},
			openDeletePassport(item) {
				this.cardPassport = item;
				this.dialogDeletePassport = true
			},
			deletePassport() {
				this.loading_btn = true
				deletePassport(this.card.id, this.cardPassport.id).then(() => {
					this.$store.commit('notification/successMessage', {title: this.$t('account.messages.success.passport_successfully_delete')});
					this.getTourist();
				}).finally(() => {
					this.dialogDeletePassport = false;
					this.cardPassport = {};
					this.loading_btn = false;
				})
			},
            deleteTourist() {
				this.loading_btn = true
                deleteTourist(this.card.id).then(() => {
					this.$store.commit('notification/successMessage', {title: this.$t('account.messages.success.tourist_successfully_delete')});
                    this.$emit('update-items');
				}).finally(() => {
					this.dialogDeleteTourist = false;
					this.loading_btn = false;
				})
			},
			passportSetMain(item) {
				passportSetMain(this.card.id, item.id).then(() => {
					this.$store.commit('notification/successMessage', {title: this.$t('account.messages.success.passport_successfully_change')});
					this.getTourist();
				})
			},
			editLoyaltyProgram(item) {
				this.cardLoyaltyProgram = item;
				this.addLoyaltyProgram = true;
			},
			openDeleteLoyaltyProgram(item) {
				this.cardLoyaltyProgram = item;
				this.dialogDeleteLoyaltyProgram = true;
			},
			deleteLoyaltyProgram() {
				this.loading_btn = true
				deleteLoyaltyProgram(this.card.id, this.cardLoyaltyProgram.id).then(() => {
					this.$store.commit('notification/successMessage', {title: this.$t('account.messages.success.loyalty_program_successfully_delete')});
					this.getTourist();
				}).finally(() => {
					this.dialogDeleteLoyaltyProgram = false;
					this.cardLoyaltyProgram = {};
					this.loading_btn = false;
				})
			},
			getTourist() {
				this.loading = true;

				getTourist(this.card.id).then(res => {
					this.user = res.data
				}).finally(() => this.loading = false)
			}
		},
		watch: {
			value() {
				if(this.value) {
					this.getTourist();
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-tourists {
		&-group {
			&-row {
				display: flex;
				flex-wrap: wrap;
				flex: 1 1 auto;
				margin: 0 -10px;
			}
			&-col {
				flex: 0 0 100%;
				max-width: 100%;
				padding-right: 10px;
				padding-left: 10px;
				@include minw($grid-breakpoints-xs) {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
			}
		}
	}
</style>
