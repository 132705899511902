<template>
	<div>
		<template v-if='loading'>
			<a-tourist-skeleton />
		</template>
		<template v-else>
			<div class='a-tourists'>
				<div class="app-container a-tourists-container">
					<div class='a-tourists-header'>
						<div class="row align-items-center a-tourists-header-row">
							<div class="col">
								<p class="a-heading-1">{{searchItems.length}} {{$helpers.declOfNum(searchItems.length, $t('account.declOfNum.tourists'))}}</p>
							</div>
							<div class="col-auto">
								<v-button small color='green white--text'
									@click='addTourist = true'
								>{{$t("account.buttons.add_tourist")}}</v-button>
							</div>
						</div>

						<div class="row align-items-center a-tourists-header-row">
							<div class="col">
								<v-text-field
									:placeholder='$t("account.tourists.search_tourist")'
									icon-prepend='search'
									name='search'
									v-model='search'
									hide-details
									class='a-tourists-input-search'
								/>
							</div>
							<div class="col-auto">
								<div class='a-sorting a-tourists-sorting'>
									<p class='a-sorting-title'>{{$t("account.sort_by")}}:</p>
									<span class='a-sorting-list'>
										<span class='a-sorting-item' :class='{"active": sorting.sortCriterion === "name"}' @click='changeSorting("name")'>{{$t("account.tourists.sorting.alphabet")}} ({{sorting.sortCriterion === "name" && sorting.sortDirection ? $t("account.tourists.sorting.alphabet_desc") : $t("account.tourists.sorting.alphabet_desc")}})</span>
										<span class='a-sorting-item' :class='{"active": sorting.sortCriterion === "created_at"}' @click='changeSorting("created_at")'>{{sorting.sortCriterion === "created_at" && sorting.sortDirection ? $t("account.tourists.sorting.date_of_killing") : $t("account.tourists.sorting.date_added")}}</span>
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class='a-tourists-content' v-if='searchItems.length'>
						<a-data-table-simple
							:items='paginationItems'
							@action-row='viewCard'
						/>

						<v-pagination
							:total='searchItems.length'
							:page='page'
							:perPage='perPage'
							@update-pagination='page = $event'
						/>
					</div>
					<p class='a-sub-heading text-center' v-else>{{$t("account.tourists.no_tourists_found")}}</p>
				</div>
			</div>
		</template>

		<a-tourists-profile
			v-model='openCard'
			:card='card'
			:addTourist='addTourist'
			@close-form-add-tourist='addTourist = false'
			@update-items='getTourists'
		/>
	</div>
</template>

<script>
	import ATouristSkeleton from '@/views/account/tourists/skeleton';
	import ADataTableSimple from '@/views/account/tourists/ADataTableSimple';
	import ATouristsProfile from '@/views/account/tourists/ATouristsProfile';
	import { getTourists } from '@/services/request';
	import _ from 'lodash'

	export default {
		data: () => ({
			search: '',
			loading: false,
			items: [],
			page: 1,
			perPage: 10,
			openCard: false,
			card: {},
			addTourist: false,
			sorting: {
				sortDirection: null,
				sortCriterion: null
			}
		}),
		components: {
			ATouristSkeleton,
			ADataTableSimple,
			ATouristsProfile
		},
		computed: {
			searchItems() {
				return this.items.filter(el => (el.name + ' ' + el.surname).toLowerCase().includes(this.search.toLowerCase()) || (el.surname + ' ' + el.name).toLowerCase().includes(this.search.toLowerCase()));
			},
			paginationItems() {
				return _.orderBy(this.searchItems, [this.sorting.sortCriterion], [this.sorting.sortDirection ? 'desc' : 'asc']).slice(this.page === 1 ? 0 : this.perPage * (this.page - 1), this.perPage * this.page);
			}
		},
		created() {
			this.getTourists();
		},
		methods: {
			getTourists() {
				this.loading = true;

				getTourists().then(res => {
					this.items = res.data
				}).finally(() => this.loading = false)
			},
			changeSorting(name) {
				if(this.sorting.sortCriterion === name) {
					this.sorting.sortCriterion = name;
					this.sorting.sortDirection = !this.sorting.sortDirection;
				} else {
					this.sorting.sortCriterion = name;
					this.sorting.sortDirection = false;
				}
			},
			viewCard($event) {
				this.openCard = true;
				this.card = $event;
			}
		},
		watch: {
			search() {
				this.page = 1;
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-tourists {
		padding: 35px 0 100px;
		@include minw( $grid-breakpoints-sm ) {
			padding: 100px * .6 0;
		}
		@include minw( $grid-breakpoints-md ) {
			padding: 100px * .8 0;
		}
		@include minw( $grid-breakpoints-lg ) {
			padding: 100px 0;
		}
		&-container {
			max-width: 1150px;
		}
		&-content {
			.v-pagination-container {
				margin-top: 20px;
			}
		}
		&-header {
			&-row {
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 25px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 25px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					flex-direction: column;
					align-items: inherit;
					.a-heading-1 {
						@include maxw( $grid-breakpoints-xs - 1 ) {
							text-align: center;
							margin-bottom: 20px;
						}
					}
					.app-btn {
						height: 47px;
						padding: 0 25px;
						margin: auto;
						display: block;
						margin-bottom: 35px;
					}
				}
			}
		}
		&-input {
			&-search {
				margin-bottom: 25px;
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 15px;
					width: 398px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					width: 398px;
				}
			}
		}
		&-sorting {
			margin-bottom: 15px;
		}
		&-dialog {
			&-header {
				.a-card-details-header-title {
					font-size: 18px;
					@include maxw( $grid-breakpoints-xs - 1 ) {
						font-size: 20px;
						font-weight: 400;
					}
				}
			}
		}
	}

	.a-skeleton-mobile {
		&-text-align {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-left: auto;
				margin-right: auto;
			}
		}
		&-width-100 {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				width: 100% !important;
			}
		}
	}
</style>
