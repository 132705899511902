<template>
	<div class='a-profile-card'>
		<div class='a-profile-card-header'>
			<div class='a-profile-card-header-content'>
				<p class='a-profile-card-title'>{{title}}</p>
			</div>
			<div class='a-profile-card-header-action'>
				<v-chip rounded size='small' color='cyprus' v-if='isMain'>{{$t("account.tourists.main")}}</v-chip>
				<a-menu :items='items_menu' :item='item' />
			</div>
		</div>

		<p class='a-profile-card-descr' v-if='tourist'>{{tourist}}</p>

		<div class='a-profile-card-item'>
			<p class='a-profile-card-text'>{{passport}}</p>
			<p class='a-profile-card-text' v-if='date'>{{date}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['item', 'title', 'tourist', 'passport', 'date', 'isMain', 'items_menu']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-profile-card {
		box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
		border-radius: 5px;
		margin-top: 20px;
		padding: 15px 20px;
		&-item {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		&-title {
			font-size: 13px;
			font-weight: 400;
			margin-bottom: 5px;
		}
		&-text,
		&-descr {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .5);
			margin-bottom: 5px;
		}
		&-descr {
			color: rgba($black, .7);
		}
		&-header {
			display: flex;
			justify-content: space-between;
			&-content {
				flex: 0 1 100%;
			}
			&-action {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				margin-right: -10px;
				.v-chip__size--small {
					padding-top: 2px;
					padding-bottom: 2px;
				}
			}
		}
	}
</style>
